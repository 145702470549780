import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RegisterInst from "./RegisterInst";
import Insts from "./Insts";
import NoInstitutions from "./NoInstitutions";
import { fetchInsts } from "../../reducers/InstSlice";
import { fetchCreds } from "../../reducers/CredSlice";
import { FetchUserLiveCreds } from "../../Network/CredApi";
import { fetchLiveInsts } from "../../Network/InstApi";
import Profile from "../Auth/Profile";
import Header from "../../components/Header";
import logo from "../../assets/images/health-management-tech-system-logo.jpg";

const Inst = () => {
	useEffect(() => {
		FetchUserCreds();
	}, []);

	const insts = useSelector((state) => state.inst.insts);
	const User = useSelector((state) => state.auth.user);

	//redux dispatch
	const dispatch = useDispatch();

	//use user logger to fetch usercredentials from database
	const FetchUserCreds = async () => {
		let LiveCreds = await FetchUserLiveCreds(User.token);
		if (parseInt(LiveCreds.status) === 200) {
			let LiveInsts = await fetchLiveInsts(
				LiveCreds.creds.map((cred) => cred.instLinker),
				User.token
			);
			if (parseInt(LiveInsts.status) === 200) {
				dispatch(fetchCreds(LiveCreds.creds));
				dispatch(fetchInsts(LiveInsts.insts));
			}
		}
	};

	return (
		<div
			style={{
				minHeight: "100vh",
				minWidth: "100vw",

				backgroundImage: `url(${logo})`,
				backgroundSize: "cover",
				backgroundRepeat: "repeat-y",
				backgroundPosition: "center",
			}}
		>
			<Header></Header>
			<div
				style={{
					marginTop: "20px",
					backgroundColor: "rgba(64, 99, 112, 0.5)",
					minHeight: "100vh",
					minWidth: "100vw",
				}}
			>
				{" "}
				<p
					className="h6 text-primary text-center"
					style={{ textDecoration: "underline", marginTop: "20px" }}
				>
					My Facilities
				</p>
				<div className="row d-flex justify-content-around m-2">
					<div className="col-6 col-md-4 d-flex align-items-stretchcol-lg-3">
						<div className="dashboard">
							<Profile from={"dash"}></Profile>
						</div>
					</div>
					<RegisterInst></RegisterInst>
					{insts.length > 0 ? (
						<Insts></Insts>
					) : (
						<NoInstitutions></NoInstitutions>
					)}
				</div>
			</div>
		</div>
	);
};

export default Inst;
