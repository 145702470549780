import React from "react";
import image from "../../assets/images/health-management-tech-system.png";
import patient from "../../assets/images/health-management-tech-system-patient.jpg";
import staff from "../../assets/images/health-management-tech-system-staff.jpg";
import health from "../../assets/images/health-management-tech-system-health.jpg";
import inventory from "../../assets/images/health-management-tech-system-inventory.jpg";
import expenses from "../../assets/images/health-management-tech-system-expenses.jpg";
import shop from "../../assets/images/health-management-tech-system-shop.jpg";
import AuthModal from "../Auth/AuthModal";
import Header from "../../components/Header";
import PwaInstall from "../../Utils/PwaInstall";

const Home = () => {
	return (
		<div
			style={{
				minHeight: "100vh",
				minWidth: "100vw",

				backgroundImage: `url(${image})`,
				backgroundSize: "cover",
				backgroundRepeat: "repeat-y",
				backgroundPosition: "center",
			}}
		>
			<Header></Header>
			<div
				style={{
					marginTop: "40px",
					backgroundColor: "rgba(64, 99, 112, 0.5)",
					minHeight: "100vh",
					minWidth: "100vw",
				}}
			>
				<div style={{ minHeight: "10vh" }}>
					<p className="text-light text-center h5 bg-dark">
						Become A World Class Manager And Administrator
					</p>
				</div>

				<div
					style={{
						backgroundColor: "rgba(255, 255, 255)",
						borderRadius: "20%",
						padding: "2vh",
					}}
				>
					{" "}
					<p className="text-center h6">
						Eliminate Stress Of Tedious Sheets And Paper Work
					</p>{" "}
					<AuthModal></AuthModal>
					<div className="d-flex justify-content-around mt-1">
						{" "}
						<a
							download
							href="https://dl.dropbox.com/scl/fi/ydb3n60pg64hm0v26h9hl/Business-Techsystem-6.0.zip?rlkey=pokmprm588rr5z3356gkfpdx3&st=yaj3f5c2&dl=0"
						>
							<button className="btn btn-secondary rounded-pill">
								Download Software (Offline & Online)
							</button>
						</a>
						<PwaInstall title={"(Online Only)"}></PwaInstall>
					</div>
				</div>
				<div className="d-flex justify-content-center">
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/qU21mhVnQ2M?si=uotmDNEreItxZd0Z"
						title="Business And Point Of Sale Management Software System"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				</div>
				<div className="row bg-transparent d-flex justify-content-around m-2">
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Staff Access</p>
							</div>
							<img
								className="card-img-top"
								src={staff}
								alt="health-management-tech-system-staff"
							/>
							<div className="mx-1">
								<p className="card-text">
									If you have many staffs you can add them and let them help you
									sell online
								</p>
								<p className="card-text">
									Staffs Can use the email you register them with to access
									their portal
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Customer Records</p>
							</div>
							<img
								className="card-img-top"
								src={patient}
								alt="health-management-tech-system-patient"
							/>
							<div className="mx-1 py-3">
								<p className="card-text"></p>
								<p className="card-text"></p>
								<p className="card-text">
									Manage Customer records with ease helps you to add and filter
									from one place
								</p>
								<p className="card-text"></p>
								<p className="card-text"></p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Shop Manager</p>
							</div>
							<img
								className="card-img-top"
								src={shop}
								alt="health-management-tech-system-shop"
							/>
							<div className="mx-1 py-3">
								<p className="card-text">
									Manage Shop with ease with product categories
								</p>
								<p className="card-text">Add products to the shop</p>
								<p className="card-text">Has a pont of sale</p>
								<p className="card-text"></p>
								<p className="card-text pb-1"></p>
							</div>
						</div>
					</div>

					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Sales Records</p>
							</div>
							<img
								className="card-img-top"
								src={health}
								alt="health-management-tech-system-health"
							/>
							<div className="mx-1 py-5">
								<p className="card-text">
									Add and download each sales for easy access
								</p>
								<p className="card-text">Also get sales summary with ease</p>
							</div>
						</div>
					</div>

					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Inventory</p>
							</div>
							<img
								className="card-img-top"
								src={inventory}
								alt="health-management-tech-system-inventory"
							/>
							<div className="mx-1 py-4">
								<p className="card-text">
									Don't Loose materials by using inventory manager
								</p>
								<p className="card-text">
									Be able to view inventory when they are received and to whom
									they are issued and when
								</p>
								<p className="card-text pb-4">
									Add inventory categories for easier management
								</p>
							</div>
						</div>
					</div>
					<div className="col-md-3 bg-transparent my-1">
						<div className="card rounded">
							<div className="card-header">
								<p className="h5 card-title">Expenses</p>
							</div>
							<img
								className="card-img-top"
								src={expenses}
								alt="health-management-tech-system-expenses"
							/>
							<div className="mx-1 py-4">
								<p className="card-text">
									Manage expenses like a proffessional
								</p>
								<p className="card-text">
									View when and who incurred a specific expense and total
									summary by month or year
								</p>
								<p className="card-text">
									Add expense categories to see which expenses are highly
									incurred
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
